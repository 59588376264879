import { useState, useCallback } from 'react';
import { TokenType } from '../../entities/repository';
import { useUserContext } from '../../context/userContext/userContext';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { navigate } from '@reach/router';

export const useOrchestratorAuthenticateUser = (): [
  null,
  boolean,
  null,
  (tokenType: TokenType, redirectUrl: string) => Promise<boolean>
] => {
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();

  const [isLoading, setIsLoading] = useState(false);

  const { isV2TokenStoreEnabled } = useFeatureToggleContext();

  const authenticateUser = useCallback(
    async (tokenType: TokenType, redirectUrl: string) => {
      setIsLoading(true);

      const url = isV2TokenStoreEnabled
        ? buddiUrls.iModelBridgeServiceUrl + '/api/AuthenticateUser'
        : buddiUrls.orchestrationServiceUrl +
          `/api/AuthenticateUser?tokenType=${tokenType}`;

      const commonRequestHeaders = {
        Authorization: 'Bearer ' + user!.access_token,
        'Content-Type': 'application/json',
      };
      const functionParameterHeaders = {
        UserId: user!.profile.sub,
        RedirectUrl: redirectUrl,
      };

      const response = await fetch(url, {
        headers: {
          ...commonRequestHeaders,
          ...functionParameterHeaders,
        },
        method: 'POST',
      });

      const responseJson = await response.json();
      if (responseJson === 'User id valid.' || responseJson.isUserAuthorized) {
        setIsLoading(false);
        return true;
      } else {
        isV2TokenStoreEnabled
          ? window.open(responseJson.urlToLogin, '_blank')
          : navigate(responseJson);

        setIsLoading(false);
        return false;
      }
    },
    [user, buddiUrls]
  );

  return [null, isLoading, null, authenticateUser];
};
