import { EcInstances } from './EcSchema/ecInstances';

export interface iModelDto {
  instanceId: string;
  schemaName: string;
  className: string;
  properties: {
    Name: string;
    Description: string;
    UserCreated: string;
    CreatedDate: string;
    Initialized: boolean;
    DataLocationId: string;
    iModelTemplate: string;
  };
}

export interface IModel {
  instanceId: string;
  schemaName: string;
  className: string;
  description: string;
  name: string;
  userCreated: string;
  createdDate: string;
  initialized: boolean;
  dataLocationId: string;
  iModelTemplate: string;
}

export const responseToIModelsMap = (
  response: EcInstances<iModelDto>
): IModel[] => {
  return response.instances.map(instance => {
    return {
      instanceId: instance.instanceId,
      schemaName: instance.schemaName,
      className: instance.className,
      description: instance.properties.Description,
      name: instance.properties.Name,
      userCreated: instance.properties.UserCreated,
      createdDate: instance.properties.CreatedDate,
      initialized: instance.properties.Initialized,
      dataLocationId: instance.properties.DataLocationId,
      iModelTemplate: instance.properties.iModelTemplate,
    } as IModel;
  });
};
