import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProjectIModels } from '../../hooks/useGetProjectIModels/useGetProjectIModels';
import { useEffect } from 'react';
import { IModel } from '../../entities/iModel';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { useCopyConnection } from '../../hooks/useCopyConnections/useCopyConnection';
import { useSetSpatialRoot } from '../../hooks/useSetSpatialRoot/useSetSpatialRoot';
import { useCreateJobSchedule } from '../../hooks/useCreateJobSchedule/useCreateJobSchedule';
import { useGetJobSchedule } from '../../hooks/useGetJobSchedule/useGetJobSchedule';
import { useToast } from '../../context/toastContext/toastContext';
import { SpatialRoot } from '../../entities/spatialRootSetting';
import { ModalProps } from '../../hooks/useModal/useModal';
import {
  Button,
  LabeledSelect,
  Modal,
  ModalButtonBar,
} from '@itwin/itwinui-react';

type CopyConnectionsJobModalProps = ModalProps & {
  projectId: string;
  currentIModelId: string;
  spatialRoot: SpatialRoot | null;
  jobs: JobDefinition[];
  closeModal: () => void;
};
export const CopyConnectionsJobModal = (
  props: CopyConnectionsJobModalProps
) => {
  const { t } = useTranslation();

  const {
    projectId,
    currentIModelId,
    spatialRoot,
    jobs,
    closeModal,
    ...modalProps
  } = props;
  const [iModels, , , fetchIModels] = useGetProjectIModels(projectId);
  const [selectedIModelId, setSelectedIModelId] = useState<string>('');
  const [, , , setSpatialRoot] = useSetSpatialRoot(projectId);
  const [, , , fetchJobSchedule] = useGetJobSchedule(
    projectId,
    currentIModelId
  );
  const [, , , createJobSchedule] = useCreateJobSchedule(
    projectId,
    selectedIModelId
  );
  const [, , , copyConnection] = useCopyConnection(projectId, selectedIModelId);
  const { toastError, toastSuccess } = useToast();

  const dropdownIModels = iModels.filter(
    (iModel: IModel) => iModel.instanceId !== currentIModelId
  );
  const options = iModels.map(x => ({ label: x.name, value: x.instanceId }));

  const close = () => {
    closeModal();
  };

  useEffect(() => {
    fetchIModels();
  }, [fetchIModels]);

  const copyConnectionsAction = async () => {
    let errorDisplayed = false;
    for (const job of jobs) {
      const response = await copyConnection(job);
      if (!response.data) {
        errorDisplayed = true;
        break;
      }

      const spatialMaster = job.files.find(file => file.isSpatialRoot);
      if (
        spatialRoot &&
        spatialRoot.jobDefinitionId === job.id &&
        spatialMaster &&
        spatialRoot.id === spatialMaster.file.id
      ) {
        const newSpatialRoot = await setSpatialRoot(
          selectedIModelId,
          spatialMaster.file,
          response.data.id
        );
        if (newSpatialRoot.error) {
          errorDisplayed = true;
          break;
        }
      }

      const schedule = await fetchJobSchedule(job);
      if (schedule && schedule.data) {
        const copiedSchedule = await createJobSchedule(
          response.data.id,
          schedule.data.schedule
        );
        if (copiedSchedule.error) {
          errorDisplayed = true;
          break;
        }
      }
    }

    errorDisplayed
      ? toastError(t('CopyConnections_ErrorMessage'))
      : toastSuccess(t('CopyConnections_SuccessMessage'));
    close();
  };

  return (
    <Modal
      {...modalProps}
      title={t('CopyConnectionsJobModal_Title')}
      onClose={close}
    >
      <div className="copy-connections-modal-message">
        <span>{t('CopyConnectionsModal_Message')}</span>
      </div>
      <LabeledSelect
        style={{ width: '100%' }}
        label={t('IModelNameInputField_Label')}
        data-testid="imodel-selection-copy-connections"
        options={options}
        value={selectedIModelId}
        onChange={value => {
          const selectedDropdownIModel = dropdownIModels.find(
            (iModel: IModel) => iModel.instanceId === value
          );
          if (selectedDropdownIModel)
            setSelectedIModelId(selectedDropdownIModel.instanceId);
        }}
        placeholder={t('SelectDropdown_SelectIModel_Placeholder')}
      />
      <ModalButtonBar>
        <Button
          styleType="high-visibility"
          onClick={copyConnectionsAction}
          disabled={selectedIModelId === ''}
        >
          {t('CopyConnectionsModalBtn_Label')}
        </Button>
        <Button onClick={close}>{t('CancelBtn_Label')}</Button>
      </ModalButtonBar>
    </Modal>
  );
};
