import './connectorRunLogs.scss';
import {
  Button,
  Text,
  LabeledInput,
  LabeledSelect,
  toaster,
} from '@itwin/itwinui-react';
import { useState } from 'react';
import { useDataApi } from '../../externalComponents/hooks/useDataApi/useDataApi';
import { assertUnreachable } from '../../externalComponents/services/uiUtils/assertUnreachable';

enum LogsType {
  Default,
  Latest,
}

enum File {
  Stdout = 'stdout.txt',
  Bridgeargs = 'bridgeargs.rsp',
}

enum Geolocation {
  EUS,
  UKS,
  AUE,
  JPE,
  SAN,
  SEA,
  NEU,
  CAC,
}

const geolocationSelectEntries = {
  [Geolocation.EUS]: { label: 'East US', value: 'EUS' },
  [Geolocation.UKS]: { label: 'UK South', value: 'UKS' },
  [Geolocation.AUE]: { label: 'Australia East', value: 'AUE' },
  [Geolocation.JPE]: { label: 'Japan East', value: 'JPE' },
  [Geolocation.SAN]: { label: 'South Africa North', value: 'SAN' },
  [Geolocation.SEA]: { label: 'South East Asia', value: 'SEA' },
  [Geolocation.NEU]: { label: 'North Europe', value: 'NEU' },
  [Geolocation.CAC]: { label: 'Canada Central', value: 'CAC' },
};

export const ConnectorRunLogs = () => {
  const [runId, setJobId] = useState('');
  const [taskId, setTaskId] = useState('');
  const [fileName, setFileName] = useState(File.Stdout);
  const [geolocation, setGeolocation] = useState(
    geolocationSelectEntries[Geolocation.EUS].value
  );
  const [, isLoading, , fetchData] = useDataApi<any>({
    initialData: {},
    initialIsLoading: false,
  });

  const getFileSelectOptions = () => {
    return Object.values(File).map(fileName => ({
      value: fileName,
      label: fileName,
    }));
  };

  const downloadLogs = async (logsType: LogsType) => {
    let actionPath: string;
    switch (logsType) {
      case LogsType.Default:
        actionPath = 'connectionLogs';
        break;
      case LogsType.Latest:
        actionPath = 'connectionLogs/lastAttempt';
        break;
      default:
        throw assertUnreachable(logsType);
    }
    const { data, response, error } = await fetchData({
      method: 'GET',
      url: `${window.location.origin}/api/${actionPath}/${runId}/${taskId}/${fileName}/${geolocation}`,
      map: x => x,
    });
    if (!error) {
      const { sasUri } = data;
      window.open(sasUri, '_self');
    } else {
      const errorText =
        response?.status && response.status >= 400 && response.status < 500
          ? error
          : 'Failed to fetch logs';
      toaster.negative(errorText, {
        duration: 5000,
        hasCloseButton: true,
      });
    }
  };

  return (
    <div id="jobRunLogsPage">
      <Text variant="headline" as="h1">
        Connector run logs
      </Text>
      <div className="flex-wrapper">
        <div className="tile-card">
          <LabeledInput
            label="Run ID"
            value={runId}
            onChange={e => {
              setJobId(e.target.value);
            }}
          />
          <LabeledInput
            label="Task ID"
            value={taskId}
            onChange={e => {
              setTaskId(e.target.value);
            }}
          />
          <LabeledSelect<File>
            label="File"
            options={getFileSelectOptions()}
            value={fileName}
            onChange={value => setFileName(value)}
          />
          <LabeledSelect<string>
            label="Geolocation"
            options={Object.values(geolocationSelectEntries)}
            value={geolocation}
            onChange={value => setGeolocation(value)}
          />
          <div className="button-flex-wrapper">
            <Button
              styleType="high-visibility"
              onClick={() => {
                downloadLogs(LogsType.Default);
              }}
              disabled={isLoading || !runId || !taskId}
            >
              Download
            </Button>
            <Button
              onClick={() => {
                downloadLogs(LogsType.Latest);
              }}
              disabled={isLoading || !runId || !taskId}
            >
              Download last attempt
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
