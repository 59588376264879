import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { RequestData, ResponseData } from '../../hooks/useDataApi/requestData';
import { responseToIModelsMap, IModel } from '../../entities/iModel';
import { useCallback, useEffect } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useErrorHandlingContext } from '../../context/errorHandlingContext/errorHandlingContext';

export const useGetProjectIModels = (
  projectId: string
): [IModel[], boolean, any, () => Promise<ResponseData<IModel[]>>] => {
  const { buddiUrls } = useBuddiContext();
  const { onErrorEncountered } = useErrorHandlingContext();

  const useDataSettings = { initialIsLoading: true, initialData: [] };
  const [iModels, isLoading, error, fetchData] =
    useDataApi<IModel[]>(useDataSettings);

  const getIModels = useCallback(() => {
    const requestData = getProjectIModelsGraphQLQuery(
      buddiUrls.iModelHubUrl,
      projectId
    );

    return fetchData(requestData);
  }, [projectId, buddiUrls, fetchData]);

  useEffect(() => {
    const err = error ? JSON.parse(error) : null;
    if (err != null && err.errorId === 'iModelHub.UserDoesNotHavePermission') {
      onErrorEncountered('UserDoesNotHavePermissionForiModelHub');
    }
  }, [error]);

  return [iModels, isLoading, error, getIModels];
};

const getProjectIModelsGraphQLQuery = (
  iModelHubUrl: string,
  projectId: string
) => {
  const iModelsMap = responseToIModelsMap;
  const iModelUrl = `${iModelHubUrl}/sv1.1/Repositories/Context--${projectId}/ContextScope/iModel`;
  const requestData = {
    url: iModelUrl,
    method: 'GET',
    map: iModelsMap,
  } as RequestData<IModel[]>;
  return requestData;
};
