import { useCallback, useState } from 'react';
import { useAppContext } from '../../context/appContext/appContext';
import { useFeatureToggleContext } from '../../externalComponents/context/featureToggleContext/featureToggleContext';
import {
  JobRun,
  JobRunState,
  JobRunTriggerType,
} from '../../externalComponents/entities/jobRunStatus/jobRunStatus';
import { mapSingleJobRunStatusV2DtoToJobRun } from '../../externalComponents/entities/jobRunStatus/jobRunStatusMapping';
import {
  RequestData,
  ResponseData,
} from '../../externalComponents/hooks/useDataApi/requestData';
import { useDataApi } from '../../externalComponents/hooks/useDataApi/useDataApi';

export const useGetRunInformation = (
  connectionDefinitionId: string,
  runId: string
): [JobRun, boolean, any, () => Promise<ResponseData<JobRun>>] => {
  const { configuration } = useAppContext();
  const { showPreprocessorJob, enableSyncErrorTotals } = useFeatureToggleContext();
  const [isLoading, setIsLoading] = useState(true);
  const useDataSettings = {
    initialData: {
      jobRunId: '',
      jobStatus: JobRunState.None,
      startTime: '',
      processingStartTime: '',
      endTime: '',
      details: '',
      triggerType: JobRunTriggerType.Manual,
      fileRuns: [],
      projectId: '',
      iModelId: '',
      connectionDefinitionId: '',
      dataLocationId: '',
    },
    initialIsLoading: true,
  };
  const [data, , error, fetchData] = useDataApi<JobRun>(useDataSettings);
  const url = `${configuration.buddiUrls.iModelBridgeService}/api/ConnectionDefinitions('${connectionDefinitionId}')/Runs('${runId}')/GetRunForBentleyUsers`;

  const getRunInformation = useCallback(async () => {
    const requestData: RequestData<JobRun> = {
      method: 'GET',
      url: url,
      map: response =>
        mapSingleJobRunStatusV2DtoToJobRun(response, showPreprocessorJob, enableSyncErrorTotals),
    };
    const response = await fetchData(requestData);
    setIsLoading(false);
    return response;
  }, [url, fetchData]);

  return [data, isLoading, error, getRunInformation];
};
