import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Jobs } from './jobs';
import { EmptyJobPage } from './emptyJobPage';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { IModel } from '../../externalComponents/entities/iModel';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../externalComponents/services/uiUtils/navigation';
import { useConnectionDefintionsContext } from '../../externalComponents/context/connectionDefinitionsContext/connectionsDefintionsContext';

export interface JobPageControllerProps extends RouteComponentProps<{}> {
  iModel: IModel;
  goToJobView: (jobNameId: string) => any;
  goToChangeMappingsView: (jobNameId: string) => any;
  isFirstNavigationInRouter: boolean;
}

export const JobPageController = (props: JobPageControllerProps) => {
  const {
    iModel,
    goToJobView,
    goToChangeMappingsView,
    isFirstNavigationInRouter,
  } = props;
  const { t } = useTranslation();

  const onCreatenewJobClick = () => {
    navigate(`${iModel.instanceId}/createConnection`);
  };

  const {
    connectionsDefintions,
    areConnectionDefintionsLoading,
    fetchConnectionDefintions,
  } = useConnectionDefintionsContext();

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const isFirstNavigationInRouterOnMount = useRef(isFirstNavigationInRouter);

  useEffect(() => {
    if (!isFirstNavigationInRouterOnMount.current) {
      fetchConnectionDefintions();
    }
  }, [fetchConnectionDefintions]);

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  return areConnectionDefintionsLoading || !isComponentMounted ? (
    <FullPageLoader>{t('FullPage_Loading_Message')}</FullPageLoader>
  ) : (
    <div className="jobs-page fit-to-parent">
      {connectionsDefintions.length > 0 ? (
        <Jobs
          iModel={iModel}
          onCreateNewJobClick={onCreatenewJobClick}
          goToJobView={goToJobView}
          goToChangeMappingsView={goToChangeMappingsView}
        />
      ) : (
        <EmptyJobPage
          iModelName={iModel.name}
          onCreateNewJobClick={onCreatenewJobClick}
        />
      )}
    </div>
  );
};
