import { useEffect } from 'react';
import { Router } from '@reach/router';
import { JobsRouter } from '../jobs/jobsRouter';
import { IModelHubView } from '../iModelHub/iModelHub';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { useGetProjectIModels } from '../../externalComponents/hooks/useGetProjectIModels/useGetProjectIModels';
import { useUsageLogger } from '../../externalComponents/context/usageLoggerContext/usageLoggerContext';
import { useTrackFeature } from '../../externalComponents/hooks/useTrackFeature/useTrackFeature';
import { Features } from '../../externalComponents/context/usageLoggerContext/featureConstants';
import { useTranslation } from 'react-i18next';

export interface InstantOnApplicationProps {
  projectId: string;
}

export const InstantOnApplication = (props: InstantOnApplicationProps) => {
  useTrackFeature(Features.PortalUsage);
  const { t } = useTranslation();
  const { projectId } = props;
  const { logUsage, logFeature } = useUsageLogger();
  const [iModels, isIModelsLoading, iModelFetchError, refetchIModels] =
    useGetProjectIModels(projectId);

  useEffect(() => {
    refetchIModels();
  }, []);

  useEffect(() => {
    logUsage();
  }, [logUsage, logFeature]);

  const isInstantOnLoading = isIModelsLoading;

  return isInstantOnLoading ? (
    <FullPageLoader>{t('FullPage_Loading_Message')}</FullPageLoader>
  ) : (
    <>
      <Router style={{ height: '100%', position: 'relative' }}>
        <IModelHubView
          path="/"
          default
          iModels={iModels}
          projectId={projectId!}
          refetchIModels={refetchIModels}
          error={iModelFetchError}
        />
        <JobsRouter
          path=":imodelId/*"
          repositories={[]}
          projectId={projectId!}
          iModels={iModels}
        />
      </Router>
    </>
  );
};
