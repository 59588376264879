import { Svg404 } from '@itwin/itwinui-illustrations-react';
import { NonIdealState } from '@itwin/itwinui-react';
import { useFeatureToggleContext } from '../../externalComponents/context/featureToggleContext/featureToggleContext';
import { ConnectorRunLogs } from './connectorRunLogs';

export const ConnectorRunLogsWrapper = () => {
  const { connectorLogsPage } = useFeatureToggleContext();
  return connectorLogsPage ? (
    <ConnectorRunLogs />
  ) : (
    <div className="fit-to-parent">
      <NonIdealState
        svg={<Svg404 />}
        heading="No access"
        description="Page not found"
      />
    </div>
  );
};
