import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/appContext/appContext';
import { useIModelContext } from '../../context/navigationContext/iModelNameContext';

// CONNECT Header README
//https://bentleycs.visualstudio.com/beconnect/_git/WP?path=%2Fsrc%2FWebPortal%2FTypeScript%2FGlobalNavigation%2FREADME.md&version=GBmaster&fullScreen=true&_a=preview
interface CONNECTHeaderProps {
  projectId?: string;
}

function useCONNECTHeader(projectId?: string) {
  const { configuration, user } = useAppContext();
  const { currentIModel } = useIModelContext();
  const connectPortalUrl = configuration.buddiUrls.connectPortalUrl;
  const [headerFooterLoaded, setHeaderFooterLoaded] = useState(false);

  useEffect(
    function addHeaderFooterScript() {
      if (connectPortalUrl) {
        const headerFooterScript = document.createElement('script');
        const linkCss = document.createElement('link');
        headerFooterScript.onload = () => {
          setHeaderFooterLoaded(true);
        };
        headerFooterScript.src = `${connectPortalUrl}/Scripts/header-footer.js`;
        linkCss.rel = 'stylesheet';
        linkCss.type = 'text/css';
        linkCss.href = `${connectPortalUrl}/Content/header-footer.css`;
        (document.head as any).appendChild(linkCss);
        (document.head as any).appendChild(headerFooterScript);
      }
    },
    [connectPortalUrl, currentIModel]
  );

  // Intitialize header & footer
  useEffect(() => {
    headerFooterLoaded &&
      //@ts-ignore
      window.GlobalHeaderFooter.setup({
        // See chart below for all the keys/values that go inside of here.
        Email: user?.profile?.email,
        HelpUrl:
          'https://communities.bentley.com/products/digital-twin-cloud-services/itwin-services/w/synchronization-wiki/47606/itwin-synchronizer-portal',
        CurrentServiceId: '3BBDF3FC-52AF-492B-A88F-6777837C1090',
        CurrentProjectId: projectId,
        LegalUrl: `${connectPortalUrl}/Legal`,
        SignOutUrl: `/logout/${projectId}${
          currentIModel?.instanceId != null
            ? '/' + currentIModel.instanceId
            : ''
        }`,
      });
  }, [connectPortalUrl, headerFooterLoaded, currentIModel]);
}

function CONNECTHeader({ projectId }: CONNECTHeaderProps) {
  useCONNECTHeader(projectId);

  return <nav id="GlobalHeader" style={{ margin: 0, zIndex: 100 }} />;
}

export default CONNECTHeader;
