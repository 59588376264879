import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SynchronizationContext } from '../../externalComponents/context/synchronizationContext/synchronizationContext';
import { useAppContext } from '../appContext/appContext';
import { useIModelContext } from '../navigationContext/iModelNameContext';
import { useProjectContext } from '../projectContext/projectContext';
import { navigate } from '../../externalComponents/services/uiUtils/navigation';

interface SynchronizationComponentsContextProps {
  children: ReactNode;
}

export const SynchronizationComponentsContext = (
  props: SynchronizationComponentsContextProps
) => {
  const { children } = props;

  const { currentIModel } = useIModelContext();
  const { currentProject } = useProjectContext();
  const { user, configuration } = useAppContext();
  const { t } = useTranslation();

  return (
    <SynchronizationContext
      projectId={currentProject.instanceId}
      imodelId={currentIModel.instanceId}
      user={user!}
      buddiUrls={{
        region: configuration.buddiRegion,
        iModelBridgeServiceUrl: configuration.buddiUrls.iModelBridgeService,
        productSettingsServiceUrl:
          configuration.buddiUrls.productSettingsServiceUrl,
        orchestrationServiceUrl:
          configuration.buddiUrls.orchestrationServiceUrl,
        iModelHubUrl: configuration.buddiUrls.iModelHubUrl,
        iModelBridgeSchedulerUrl:
          configuration.buddiUrls.iModelBridgeSchedulerUrl,
        documentServiceUrl: configuration.buddiUrls.documentServiceUrl,
        rbacServiceUrl: configuration.buddiUrls.rbacServiceUrl,
        logDispatcherUrl: configuration.buddiUrls.logDispatcherUrl,
        iTwinBridgePortalUrl: window.location.origin,
        projectWise365Url: configuration.buddiUrls.projectWise365Url,
        badgersUrl: configuration.buddiUrls.badgersUrl,
      }}
      launchDarkleyClientId={configuration.launchDarkleyClientId}
      onErrorEncountered={(error: 'UserDoesNotHavePermissionForiModelHub') => {
        if (error === 'UserDoesNotHavePermissionForiModelHub') {
          navigate('/errorPage', true, undefined, {
            state: {
              errorType: '403',
              errorMessage: t('NoProjectImodelPermission_ErrorMessage'),
            },
          });
        }
      }}
      onUserUnauthenticated={() => {
        navigate(
          `${window.location.origin}/login${window.location.pathname}${window.location.search}`
        );
      }}
    >
      {children}
    </SynchronizationContext>
  );
};
