import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, NonIdealState, LabeledInput } from '@itwin/itwinui-react';
import { useGetRunInformation } from '../../hooks/useGetRunInformation/useGetRunInformation';
import '../../components/jobView/jobView.scss';
import './runInformation.scss';
import { useToast } from '../../externalComponents/context/toastContext/toastContext';
import { JobRun } from '../../externalComponents/entities/jobRunStatus/jobRunStatus';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { useUserContext } from '../../externalComponents/context/userContext/userContext';
import { RunInformationStatusTable } from '../../externalComponents/components/runInformationStatusTable/runInformationStatusTable';
import { SvgError } from '@itwin/itwinui-illustrations-react';

export const RunInformation = () => {
  const { t } = useTranslation();
  const [runId, setRunId] = useState('');
  const [connectionDefinitionId, setConnectionDefinitionId] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [jobRuns, setjobRuns] = useState<JobRun[]>([]);
  const [jobRun, isRunInFormationLoading, , fetchRunInformation] =
    useGetRunInformation(connectionDefinitionId, runId);

  const { toastError } = useToast();
  const { user } = useUserContext();
  const [domain] = useState(
    user.profile.email?.split('@')[1].toLocaleLowerCase()
  );
  const fetchStatuses = useCallback(async () => {
    setshowLoader(true);
    const runInformation = await fetchRunInformation();
    if (!runInformation.ok && runInformation?.response?.status === 404) {
      setshowLoader(false);
      return toastError(t('FailedToGetRunInformation_Toast'));
    }
    const jobRuns: JobRun[] = [];
    jobRuns.push(runInformation.data!);
    setjobRuns(jobRuns);
    setshowLoader(false);
  }, [fetchRunInformation]);

  const runJobAndHandle = async () => {
    return;
  };

  return (
    <>
      {domain === 'bentley.com' || domain === 'bentley.m8r.co' ? (
        <div>
          <LabeledInput
            label={t('RunInformationInputField_ConnectionDefinitionId')}
            name="connectionDefinitionId"
            value={connectionDefinitionId}
            onChange={(e: any) => {
              setConnectionDefinitionId(e.target.value);
            }}
          />
          <br></br>
          <LabeledInput
            label={t('RunInformationInputField_RunId')}
            name="runId"
            value={runId}
            onChange={(e: any) => {
              setRunId(e.target.value);
            }}
          />
          <br></br>
          <Button
            disabled={!runId || !connectionDefinitionId}
            onClick={fetchStatuses}
          >
            {t('ShowRunInformationBtn_Label')}
          </Button>

          <div>
            {showLoader ? (
              <FullPageLoader>
                {t('FullPage_LoadingRunInformation_Message')}
              </FullPageLoader>
            ) : !isRunInFormationLoading ? (
              <div className="run-information-details">
                <div className="job-view-row-2">
                  <div className="tile-card tile-card-high table-wrapper filepicker-wrapper job-run-details-table">
                    <RunInformationStatusTable
                      projectId={jobRun.projectId}
                      iModelId={jobRun.iModelId}
                      jobStatuses={jobRuns}
                      enableDownloadLogsOption={true}
                      runJobAndHandle={runJobAndHandle}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <NonIdealState
          svg={<SvgError />}
          heading="No access"
          description="Page is not accessible"
        />
      )}
    </>
  );
};
