import React, {
  createContext,
  ProviderProps,
  useContext,
  useState,
} from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { ResponseData } from '../../hooks/useDataApi/requestData';
import { useGetJobs } from '../../hooks/useGetJobs/useGetJobs';

export interface IConnectionDefintionsContext {
  connectionsDefintions: JobDefinition[];
  areConnectionDefintionsLoading: boolean;
  connectionsDefintionsError: any;
  fetchConnectionDefintions: () => Promise<ResponseData<JobDefinition[]>>;
  clearConnectionDefinitions: () => void;
}

export const ConnectionDefintionsContext =
  createContext<IConnectionDefintionsContext>({
    connectionsDefintions: [],
    areConnectionDefintionsLoading: true,
    connectionsDefintionsError: {},
    fetchConnectionDefintions: async () => {
      return {} as ResponseData<JobDefinition[]>;
    },
    clearConnectionDefinitions: () => {},
  });

export interface ConnectionDefintionsContextProviderProps
  extends Omit<ProviderProps<IConnectionDefintionsContext>, 'value'> {}

export const ConnectionDefintionsContextProvider = ({
  ...props
}: ConnectionDefintionsContextProviderProps) => {
  const { projectId, iModelId } = useBentleyContext();

  const [, areConnectionDefintionsLoading, jobDefError, fetchJobDefinitions] =
    useGetJobs(projectId, iModelId);

  const [connectionsDefintions, setConnectionsDefintions] = useState<
    JobDefinition[]
  >([]);

  const fetchConnectionDefintions = useCallback(async () => {
    const connectionDefinitionsResponse = await fetchJobDefinitions();
    setConnectionsDefintions(connectionDefinitionsResponse.data ?? []);

    return connectionDefinitionsResponse;
  }, [fetchJobDefinitions]);

  const clearConnectionDefinitions = useCallback(() => {
    setConnectionsDefintions([]);
  }, []);

  useEffect(() => {
    const fetchJobDefinitionsAsync = async () => {
      if (iModelId !== undefined) {
        await fetchConnectionDefintions();
      }
    };

    fetchJobDefinitionsAsync();
  }, [fetchConnectionDefintions, iModelId]);

  return (
    <ConnectionDefintionsContext.Provider
      value={{
        connectionsDefintions,
        areConnectionDefintionsLoading,
        connectionsDefintionsError: jobDefError,
        fetchConnectionDefintions,
        clearConnectionDefinitions,
      }}
      {...props}
    />
  );
};

export const useConnectionDefintionsContext = () => {
  const context = useContext(ConnectionDefintionsContext);
  if (context == null) {
    throw new Error(
      'useConnectionDefintionsContext must be used inside provider'
    );
  }
  return context;
};
